/* ** TOOLTIP TIMELINE ** */
.tooltip_timeline_container {
	padding: 12px 12px 0 12px;
}

.tooltip_timeline_container > li:global(.ant-timeline-item) {
	padding-bottom: 8px;
}
.tooltip_timeline_container :global(.ant-timeline-item-last > .ant-timeline-item-content) {
	min-height: auto; /* reset antd height */
}

.tooltip_timeline_container .grey_text {
	color: var(--info-color);
}
.tooltip_timeline_container .highlight_text {
	color: var(--ant-primary-color);
}

/* ** CURRENT STEP ** */
.current_timeline_step {
	max-width: 135px;

	font-size: 16px;
	color: var(--ant-primary-color);
}
.current_timeline_step_description {
	color: inherit;
}
