a.address_link_button {
	width: 100%;
	min-width: 0;
	text-align: start;
	display: inline-flex;
	align-items: center;
}

a.address_link_button span:last-of-type {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}
