.banners_carousel {
	padding: 12px 0 28px 0;
}

.banners_carousel .banner_image.small {
	width: 100%;
	height: 90px;
}
.banners_carousel .banner_image.large {
	width: 100%;
	height: 160px;
}

.banners_carousel .banner_image img {
	height: 100%;
	max-width: 100%;
	object-fit: contain;
	object-position: center;
}

.banners_carousel,
.banners_carousel .banner_image img {
	border-radius: 12px;
}

/** antd styles override **/
.banners_carousel :global(.slick-dots li button),
.banners_carousel :global(.slick-dots li.slick-active button) {
	background: var(--ant-primary-color);
}

/* Note: To fix and bypass the Antd component focus issue */
.banners_carousel :global(.slick-dots li button:focus) {
	opacity: 0.3;
}
.banners_carousel :global(.slick-dots li.slick-active button:focus) {
	opacity: 1;
}
