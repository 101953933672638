.time_select_container {
	width: 100%;
}
.time_select_container :first-of-type {
	flex-shrink: 0;
}

label:global(.ant-radio-wrapper) {
	margin: 0;
}

.time_select {
	min-width: 86px;
}

.alert_msg:global(.ant-typography) {
	font-size: 12px;
	color: var(--ant-primary-color);
}
