@import './assets/fonts/Jost/font-jost.css';
@import './styles/antd-overrides.css';
@import './styles/animation-pulse.css';
@import './styles/common.css';
@import 'react-device-frameset/styles/marvel-devices.min.css';

#root {
	white-space: pre-line;
}

html,
body {
	overflow: hidden;
}

html {
	font-size: 16px;
	box-sizing: border-box;
	color: var(--font-color);
}

*,
*:before,
*:after {
	box-sizing: inherit;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul {
	margin: 0;
	padding: 0;
	outline: 0;
	font-weight: normal;
}

ol,
ul {
	list-style: none;
}

img {
	max-width: 100%;
	height: auto;
}

a {
	color: inherit;
	text-decoration: inherit;
}

body {
	font-family: 'Jost', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
		'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.rtl {
	direction: rtl;
}

.flag_img {
	width: 16px;
	height: 16px;
	max-width: unset;
}

/* typography  */
.h3 {
	color: var(--character-title-85, rgba(var(--rgb-black), 0.85));
	font-size: 20px;
	font-style: normal;
	font-weight: 500;
	line-height: 28px; /* 140% */
}

.h5 {
	color: var(--character-title-85, rgba(var(--rgb-black), 0.85));
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 24px; /* 150% */
}

.body_regular_secondary {
	color: var(--character-secondary-45, rgba(var(--rgb-black), 0.45));
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 22px; /* 157.143% */
}

.flag_img {
	width: 16px;
	height: 16px;
	max-width: unset;
}

.iframe_no_border {
	border: unset;
}

.bullet {
	display: list-item;
	list-style-type: disc;
	list-style-position: inside;
}
