.country_select {
	width: 155px;
}

.areas_select {
	width: 200px;
}

.time_zone_select {
	width: 245px;
}

.country_select :global(.ant-select-selection-item) {
	text-overflow: unset;
}

.country_name {
	width: 93px;
	overflow: hidden;
	text-overflow: ellipsis;
}

.table_column_filter :global(.ant-select-selection-item) {
	background: transparent !important;
}

.table_column_filter:global(.ant-select-multiple.ant-select-lg .ant-select-selection-overflow) {
	flex-wrap: nowrap;
}
