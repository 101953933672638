:root {
	--table-border-color: #7d7d7d;
	--cell-height: 0.85em;
}

.table {
	border-collapse: collapse;
	width: 100%;
}

.table_header_row {
	font-size: 12px;
}

.table_header_weekday {
	font-weight: 600;
}

.table_hours_column {
	position: relative;
	height: var(--cell-height);
	font-size: 12px;
	width: 40px;
	padding-right: 8px;
	user-select: none;
}

tr:nth-child(odd) .table_hours_column::before {
	content: attr(data-time);
	top: 0;
}

.table_hours_column::before,
.table_hours_column::after {
	position: absolute;
	right: 0;
	width: 90%;
	margin: 0 0 0 10%;
	font-size: var(--cell-height);
}

.table_cells {
	border-left: 1px solid var(--table-border-color);
	border-right: 1px solid var(--table-border-color);
	width: calc(100% / 7);
}

.table tr .table_cells {
	border-top: 1px solid var(--table-border-color);
}
.table tr:nth-child(even) .table_cells {
	border-top: 1px dashed var(--grey-scale-2);
}
.table tr:last-child .table_cells {
	border-bottom: 1px solid var(--table-border-color);
}

.table_cells.table_cells_open {
	background-color: var(--ant-primary-2);
	cursor: pointer;
}

.table_cells.table_cells_close {
	background-color: var(--background-color);
}

.table_cells.pre_paint {
	background-color: var(--ant-primary-1);
}
