.table_actions_wrapper {
	display: flex;
	gap: 8px;
}

/* ** EXTRA ACTIONS ** */
div.table_extra_actions_wrapper {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	width: 100%;
}
div.table_extra_actions_wrapper > :global(.ant-space-item) {
	width: 100%;
}

div.extra_actions {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	gap: 8px;
}

/* ** SEARCH ** */
span.search_input {
	width: 410px;
	border-radius: 8px;
	margin-bottom: 16px;
}

/* ** RESET FILTERS BTN ** */
.reset_filters_btn {
	margin-left: auto;
	margin-bottom: 16px;
}
