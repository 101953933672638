.sidebar {
	border-radius: 16px;
	background: var(--background-color);
}

.logo_container {
	flex: 0;
}
.app_logo {
	height: 90px;
	width: auto;
	max-width: 100%;
	display: block;
	padding: 0 24px;
	pointer-events: none;
	transition: all 0.5s ease-in-out;
	user-select: none;

	margin-bottom: 40px;
}

.top_menu {
	flex: 1;
	overflow: auto;
}
.top_menu:global(.ant-menu-light.ant-menu-root.ant-menu-inline) {
	border-inline-end: none;
}
.top_menu:global(.ant-menu.ltr) {
	padding-left: 16px;
}

.top_menu:global(.ant-menu.rtl) {
	padding-right: 16px;
}

.top_menu :global(.ant-menu-item),
.top_menu :global(.ant-menu-submenu-title) {
	border: none;
	margin-left: 0;
	margin-right: 0;
	width: 100%;
}

.top_menu:global(.ltr .ant-menu-submenu-title),
.top_menu:global(.ltr .ant-menu-item) {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}

.top_menu:global(.rtl .ant-menu-submenu-title),
.top_menu:global(.rtl .ant-menu-item) {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}

button.custom_slider_trigger {
	position: absolute;
	top: 56px;
	display: flex;
	align-items: center;
	justify-content: center;
}

button.custom_slider_trigger:global(.rtl) {
	left: -16px;
}
button.custom_slider_trigger:global(.ltr) {
	right: -16px;
}

.bottom_menu {
	flex: 0;
	border-top: solid 1px #f2f4f7;
	margin-bottom: 12px;
}

.bottom_menu :global(.ant-menu-item.ant-menu-item-active) {
	background-color: var(--grey-background);
	color: var(--font-color);
}
.bottom_menu :global(.ant-menu-light.ant-menu-root.ant-menu-inline) {
	border-inline-end: none;
}
