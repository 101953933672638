.banner_order_preview {
	width: 100%;
	padding: 24px 12px;
	overflow-x: auto;
}

.fake_store_card {
	width: 360px; /* width = 90 (height) * 4 (ratio 4:1) */
}

.banner_img {
	object-fit: cover;
	border-bottom-left-radius: 6px;
	border-bottom-right-radius: 6px;
}
