div.img_box_container {
	min-width: fit-content;

	display: inline-flex;
	align-items: center;
	justify-content: center;
}
div.img_box_container.bordered {
	padding: 16px;
	border-radius: 4px;
	border: 1px solid var(--border-color);
}

div.img {
	border-radius: 4px;
	color: var(--info-color);
}
div.img img {
	width: auto; /* Note: It is needed to resolve the width render issue on Safari! */
}

div.empty_img {
	width: 162px;
	margin: 8px 0;
	user-select: none;
}

div.extra_info_wrapper {
	width: 100%;
	user-select: none;
	color: var(--info-color);
}
div.extra_info_wrapper .info {
	font-size: 12px;
	color: inherit;
}
