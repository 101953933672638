div.extra_info_wrapper {
	width: 100%;
	margin-top: 16px;
	user-select: none;
	color: var(--info-color);
}
div.extra_info_wrapper .info {
	font-size: 12px;
	color: inherit;
}
