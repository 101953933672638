:root {
	--border-radius: 14px;
	--margin: 20px;
	--padding-3: 24px;
	--padding-4: 32px;
}

/* ==== LAYOUT ==== */

.layout {
	height: 100vh;
}

.sider {
	background-color: var(--background-color);
	margin: var(--margin) 0 var(--margin) var(--margin);
	border-radius: var(--border-radius);
}

.header {
	margin: var(--margin) var(--margin) 0 var(--margin);
	padding: var(--padding-3) var(--padding-4);
	background-color: var(--background-color);
	border-radius: var(--border-radius);
}
.header:global(.ant-layout-header) {
	height: auto;
}

.content {
	margin: var(--margin);
	padding: var(--padding-3);
	background-color: var(--background-color);
	border-radius: var(--border-radius);
}

.menu {
	padding: 40px 0 40px 20px;
}
.menu :global(.ant-menu-item) {
	border-radius: var(--border-radius) 0 0 var(--border-radius);
}
.menu :global(.ant-menu-item::after) {
	border: none;
}

.logo {
	position: relative;
	padding: 0 var(--padding-3);
	margin-bottom: var(--margin);
}
.logo .sub_logo {
	position: absolute;
	bottom: -16px;
	right: 8px;
}

.logo .sub_logo svg {
	width: 50px;
	height: 50px;
}
