::selection {
	background: rgb(196, 196, 196, 0.5);
}
::-moz-selection {
	/* Code for Firefox */
	background: rgb(196, 196, 196, 0.5);
}

.ant-btn-primary {
	text-shadow: none !important;
}
.ant-btn-link {
	padding: 0;
	height: auto;
	border: none;
	color: inherit;
}

.ant-select .ant-select-selector {
	box-shadow: none !important;
}

.ant-descriptions-item-label {
	color: #3b424b !important;
}

.ant-descriptions-item-container {
	align-items: center;
}
.ant-descriptions-item-container .ant-descriptions-item-content,
.ant-descriptions-item-container .ant-descriptions-item-label {
	min-width: 0; /* hint to respect the ellipsis */
}

.ant-layout-sider-collapsed .ant-layout-sider-children img {
	padding: 0 4px;
}

.ant-tabs-content-holder {
	display: flex; /* !Note: to solve 100% height problem on TabPanes (Dispatcher page) */
}
.ant-tabs-tab {
	user-select: none;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
	text-shadow: none !important;
}

.ant-collapse {
	border-radius: 4px;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header {
	height: 46px;
	padding: 0 16px;
	align-items: center;
}
.ant-collapse > .ant-collapse-item .ant-collapse-header-collapsible-only .ant-collapse-header-text {
	height: 100%;
	flex: 1;

	display: flex;
	align-items: center;
}
.ant-collapse-content > .ant-collapse-content-box {
	padding: 30px;
}

.ant-card {
	border-radius: 6px;
}

.ant-page-header {
	padding: 0 0 16px 0;
}

.ant-page-header-heading-title {
	text-transform: uppercase;
}

.ant-page-header-heading-tags {
	display: inline-flex;
	align-items: center;
}
.ant-page-header-heading-extra {
	align-self: center;
}

.ant-btn .anticon {
	transition: transform 0.4s;
}
.anticon.rotate-180 {
	transform: rotate(180deg);
}

.ant-table-summary,
.ant-table-summary .ant-table-cell {
	background: var(--grey-scale-1) !important;
}

.ant-table-cell .ant-tag,
.ant-card-head-title .ant-tag {
	margin: 0;
}

.ant-switch:focus {
	outline: none;
	box-shadow: none;
}

.ant-modal-header {
	margin-bottom: 16px !important;
}

.ant-btn:not(.ant-btn-circle),
.ant-modal-content {
	border-radius: 4px;
}

.ant-dropdown-button > .ant-btn:first-child:not(:last-child) {
	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px;
}

.ant-dropdown-button > .ant-dropdown-trigger:last-child:not(:first-child) {
	border-top-right-radius: 4px;
	border-bottom-right-radius: 4px;
}

.ant-modal-title {
	font-weight: 600;
	text-transform: uppercase;
}
.ant-modal-footer {
	border-top: none;
}
.ant-form-item-explain {
	font-size: 12px;
	user-select: none;
}
.ant-form-item-label {
	user-select: none;
}

.ant-tree .ant-tree-node-content-wrapper {
	padding: 4px 6px;
}
.ant-tree-switcher {
	align-self: center;
}

.ant-badge .ant-badge-count {
	box-shadow: none;
	background: var(--ant-primary-color);
}

/* Dispatcher Screen */
.dispatcher-tabs > .ant-tabs-nav .ant-tabs-tab-active {
	background: var(--ant-primary-1);
	border-color: rgba(216, 31, 81, 0.5);
}

.ant-message-custom-content {
	display: flex;
}

.disputed-orders-notification .ant-notification-notice-message {
	font-weight: 600;
	user-select: none;
}
.disputed-orders-notification .ant-notification-notice-description {
	margin-top: 12px;
}

.dispatcher-tabs .ant-steps-small .ant-steps-item-custom .ant-steps-item-icon > .ant-steps-icon {
	font-size: 20px;
	line-height: 22px;
}
.dispatcher-tabs .ant-steps-item-disabled {
	cursor: default;
}
.dispatcher-tabs .ant-steps-item.ant-steps-item-active .ant-steps-item-content > .ant-steps-item-description {
	color: var(--ant-primary-color);
}

/* Tracking Drivers Screen */
.tracking_driver_desc_item .ant-descriptions-item-container {
	align-items: flex-start;
}

/* Branch Details Screen */
.branch_clone_confirm_modal.ant-modal-confirm-confirm .ant-modal-confirm-body > .anticon {
	color: var(--ant-success-color);
}

/* Product Options Screen Clone Modal */
.clone_preview_table .ant-collapse-content-box {
	padding: 0;
}

/* Order Details and Customer Support Request Details Screen */
.order-details .ant-table-row.ant-table-row-level-1,
.customer-support-request-details .ant-table-row.ant-table-row-level-1 {
	font-size: 12px;
	color: var(--info-color);
}

/* Table Single Filters */
/* TEMPORARY FIX - until Antd fixes it */
.ant-table-filter-dropdown .ant-dropdown-menu-title-content > span:last-of-type {
	padding-inline-start: 8px;
}
