@font-face {
	font-family: 'Jost';
	src: local('JostRegular'), url('./Jost-Regular.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Jost';
	src: local('JostMedium'), url('./Jost-Medium.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Jost';
	src: local('JostBold'), url('./Jost-Bold.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'Jost';
	src: local('JostSemiBold'), url('./Jost-SemiBold.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
}
