.copy_button:global(.ant-btn) {
	width: 100%; /* necessary to respect the ellipsis */
	max-width: max-content;
}

.copy_button:global(.ant-btn),
.copy_button:global(.ant-btn):hover {
	color: var(--ant-primary-color);
}

.copy_button:global(.ant-btn) .ellipsis {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
