.total_balance {
	font-size: 16px;
}

.total_balance_value {
	font-size: 18px;
	font-weight: bold;
	display: block;
	min-width: 100px;
}
