.sort_index {
	min-width: 22px;
	justify-content: center;
	display: flex;
}

.image_col {
	object-fit: contain;
	background-color: var(--grey-scale-2);
}

.form {
	/* height: calc(100% - 56px); (100% - page_header_height) */
	height: 100%; /* (100% - page_header_height) */
	overflow-y: auto;
	overflow-x: hidden;
}
.align_buttons {
	margin-top: 6px;
}

.list li > div {
	width: 100%;
}

.remove_button {
	align-items: flex-end;
}

.vendor_input {
	width: calc(100% - 220px);
	min-width: 324px;
}

.alert {
	margin-bottom: 20px;
	width: 100%;
}
