.card_body {
	overflow-x: auto;
	padding: 12px;
}

.vendors_card {
	width: 160px;
	min-width: 160px;
	overflow: hidden;
}

.vendors_card_cover {
	width: 100%;
	height: 90px;
	background-color: var(--grey-scale-2);
	overflow: hidden;
}

.vendors_card_cover img {
	object-fit: contain;
}
