.open_btn {
	width: 155px;
}

.fixed_header {
	box-shadow: 1px 8px 5px var(--grey-scale-1);
	padding: 0 20px 20px;
}

.fixed_header,
.footer {
	z-index: 3;
}

.table_container {
	padding-top: 8px;
	height: 100%;
	overflow: auto;
}

.table {
	padding: 0 20px;
}

.table_row:hover {
	cursor: pointer;
	user-select: none;
}

.footer {
	box-shadow: 1px -8px 5px var(--grey-scale-1);
	background: var(--background-color);
}

.areas_tooltip {
	color: white;
}
