.day_wrap {
	display: flex;
	margin: 1rem 0;
}

.title_wrap {
	display: flex;
	min-width: 120px;
}

.title {
	font-weight: 600;
}
