.flex,
.flex-center {
	display: flex;
}

.flex-fill {
	flex: 1 1 auto;
}
.flex-column {
	flex-direction: column;
}

.flex-shrink-zero {
	flex-shrink: 0;
}

.items-end {
	align-items: flex-end;
}

.justify-end {
	justify-content: flex-end;
}

.justify-space-between {
	justify-content: space-between;
}

.justify-center,
.flex-center {
	justify-content: center;
}

.flex-center {
	align-items: center;
}

.h-100 {
	height: 100%;
}

.w-100 {
	width: 100%;
}

.overflow-scroll-y {
	overflow-x: hidden;
	overflow-y: auto;
}

.text-uppercase {
	text-transform: uppercase;
}

.position-relative {
	position: relative;
}

.visibility-hidden {
	visibility: hidden;
}

.min-w-save-btn {
	min-width: 130px;
}

.text-splitter::before {
	color: var(--grey-scale-3);
	content: '|';
}
