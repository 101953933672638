:root {
	--background-rgba-color: rgba(0, 0, 0, 0.06);
}

.device_skeleton_container {
	padding: 24px 16px;
}

.device_skeleton_container .fake_vendor_skeleton:global(.ant-skeleton-input) {
	height: 164px;
}

.device_skeleton_container .fake_account_skeleton,
.device_skeleton_container .fake_account_my_orders_skeleton,
.device_skeleton_container .fake_account_my_favorites_skeleton {
	border-radius: 8px;
	color: rgba(0, 0, 0, 0.45);
	background-color: var(--background-rgba-color);
}

.device_skeleton_container .fake_account_skeleton {
	height: 180px;
	padding: 20px;
}
.device_skeleton_container .fake_account_skeleton :global(.ant-avatar) {
	background-color: var(--background-rgba-color);
}

.device_skeleton_container .fake_account_my_orders_skeleton {
	height: 150px;
	padding: 16px;
}

.device_skeleton_container .fake_account_my_favorites_skeleton {
	height: 100px;
	padding: 16px;
}
