.save_btn {
	width: 100%;
	min-width: 120px;
}

.polygon_edit_buttons {
	flex: 1;
	height: 100%;
	padding: 12px;
}
